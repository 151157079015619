<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="text-none" :disabled="btnDisabled" small v-bind="attrs" v-on="on" color="red" outlined>
                <v-icon small left>mdi-account-remove</v-icon>
                Remove
            </v-btn>
        </template>
        <v-card rounded flat class="rounded-lg">
            <v-card-title class="text-h6 primary">Remove user {{ user.email }}?</v-card-title>
            <v-card-text class="mt-8 body-1">
                Are you sure you want to remove this user from your organization? This action cannot be taken back.
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn width="50%" rounded color="red" text @click="doDelete">Yes</v-btn>
                <v-btn width="50%" rounded color="green" text @click="close">No</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import { ref } from "@vue/composition-api";
import axios from "axios";

import { formData, useDialog } from "@/features/dialog.js";

export default {
    props: {
        user: Object,
        btnDisabled: Boolean,
    },
    components: {},
    setup(props) {

        const { errorMessages } = useDialog();

        function doDelete() {
            axios
                .delete(`/api/v1/organization_users/${props.user.guid}`) // update to proper REST
                .then(() => {
                    location.reload();
                })
                .catch((error) => {
                    errorMessages.value = error.response.data.detail;
                });
        }

        return {
            formData,
            doDelete,
            ...useDialog(),
        };
    },
};
</script>
