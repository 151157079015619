<template>
    <v-dialog v-model="dialog" width="600">
        <template v-slot:activator="{ on }">
            <ButtonNew :action="on.click" label="Invite" />
        </template>
        <v-card class="rounded-lg">
            <v-card-title class="text-h6 primary">Invite users into your organization</v-card-title>
            <v-card-text>
                <v-textarea
                    v-model="emails"
                    filled
                    label="Email addresses"
                    auto-grow
                    value=""
                    clearable
                    autocomplete="email"
                    hint="one or more email addresses separated by a space, comma, semicolon or one email address per line"
                    persistent-hint
                    background-color="grey darken-4"
                ></v-textarea>
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <div class="text-subtitle-2">Give read and write privileges</div>
                            <v-switch
                                color="primary"
                                v-model="giveRW"
                                @change="changeGiveRW"
                                class="mt-0 pt-0"
                                hint="Users will be added as read-only by default."
                                persistent-hint
                            ></v-switch>
                        </v-col>
                        <v-col cols="6">
                            <div class="text-subtitle-2">Give administrator privileges</div>
                            <v-switch
                                color="primary"
                                v-model="giveAdmin"
                                @change="changeGiveAdmin"
                                class="mt-0 pt-0"
                                hint="Organization administrators can change anything under your organization account, remove/invite new users and change subscription details."
                                persistent-hint
                            ></v-switch>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn width="50%" rounded color="green" text @click="invite" :disabled="addDisabled">Invite</v-btn>
                <v-btn width="50%" rounded color="red" text @click="close">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { ref } from "@vue/composition-api";
import axios from "axios";

import ButtonNew from "@/components/ButtonNew.vue";

export default {
    props: {},
    components: {
        ButtonNew,
    },
    setup() {
        const emails = ref("");
        const giveRW = ref(false);
        const giveAdmin = ref(false);
        const dialog = ref(false);
        const errorMessages = ref("");
        const successMessages = ref("");
        const addDisabled = ref(false);

        function changeGiveAdmin() {
            if (giveAdmin.value) {
                giveRW.value = true;
            }
        }

        function changeGiveRW() {
            if (!giveRW.value) {
                giveAdmin.value = false;
            }
        }

        function close() {
            dialog.value = false;
            errorMessages.value = "";
            successMessages.value = "";
            addDisabled.value = false;
            emails.value = "";
            giveRW.value = false;
            giveAdmin.value = false;
        }

        function invite() {
            addDisabled.value = true;

            const data = {
                emails: emails.value,
                giveRW: giveRW.value,
            };
            axios
                .post("/api/v1/invite", data)
                .then(() => {
                    successMessages.value = "Users invited successfully!";
                    setTimeout(() => {
                        close();
                    }, 2000);
                    location.reload();
                })
                .catch((error) => {
                    errorMessages.value = error.response.data.detail;
                });
        }

        return {
            changeGiveAdmin,
            changeGiveRW,
            invite,
            close,
            emails,
            giveRW,
            giveAdmin,
            dialog,
            errorMessages,
            successMessages,
            addDisabled,
        };
    },
};
</script>
