import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

import { ref } from "@vue/composition-api";

const formData = ref({});

function useDialog() {
    const dialog = ref(false);
    const errorMessages = ref("");
    const successMessages = ref("");

    function close() {
        dialog.value = false;
        errorMessages.value = "";
        successMessages.value = "";
        formData.value = {};
    }

    return {
        dialog,
        errorMessages,
        successMessages,
        close,
    };
}

export { formData, useDialog };
