<template>
    <v-container>
        <v-row no-gutters>
            <v-col cols="12">
                <MyCard height="80vh">
                    <template v-slot:header>
                        Users in your organization
                        <v-spacer />
                        <InviteUsers />
                    </template>
                    <v-data-table
                        :items="users"
                        :headers="headers"
                        disable-pagination
                        disable-filtering
                        hide-default-footer
                        v-if="!(orgDetailsLoading || roleDetailsLoading)"
                    >
                        <template #item.role="{ item }">
                            {{ roleDetails[item.role].name }}
                            <!-- {{ item }} -->
                        </template>
                        <template #item.last_login="{ item }">
                            {{ item.last_login | ts_iso_format }}
                            <!-- {{ item.last_login }} -->
                        </template>
                        <template #item.remove="{ item }">
                            <OrgUserDelete :user="item" :btnDisabled="username === item.email" />
                        </template>
                    </v-data-table>
                </MyCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import InviteUsers from "@/components/InviteUsers.vue";
import MyCard from "@/components/MyCard.vue";
import OrgUserDelete from "@/components/OrgUserDelete.vue";

import { ref, onMounted, getCurrentInstance } from "@vue/composition-api";
import axios from "axios";

import { useStore } from "@/store/index.js";

export default {
    props: {},
    components: {
        InviteUsers,
        MyCard,
        OrgUserDelete,
    },
    emits: ["breadcrumb"],
    setup() {
        const { emit } = getCurrentInstance();
        const store = useStore();

        const users = ref([]);
        const roleDetails = ref([]);
        const orgDetailsLoading = ref(true);
        const roleDetailsLoading = ref(true);

        const headers = [
            { text: "Email", value: "email", show: true, width: "35%" },
            { text: "Role", value: "role", show: true, width: "35%" },
            { text: "Last login", value: "last_login", show: true, width: "20%" },
            { text: "Remove", value: "remove", show: true, width: "10%" },
        ];

        onMounted(() => {
            emit("breadcrumb", [
                {
                    text: "Administration",
                    disabled: false,
                    href: "/orgadmin",
                },
            ]);

            axios.get("/api/v1/organization_users").then((response) => {
                users.value = response.data;
                orgDetailsLoading.value = false;
            });

            axios.get("/api/v1/roledetails").then((response) => {
                roleDetails.value = response.data;
                roleDetailsLoading.value = false;
            });
        });

        return {
            users,
            roleDetails,
            orgDetailsLoading,
            roleDetailsLoading,
            headers,
            username: store.state.session.username,
        };
    },
};
</script>
